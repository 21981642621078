<template>
  <div class="wrapper">
    <org-tree ref="treeRef" @node-click="handleNodeClick"></org-tree>
    <div class="table">
      <el-form ref="form" inline :model="queryForm" label-width="80px">
        <el-form-item prop="search">
          <el-input v-model="queryForm.search" placeholder="请输入姓名/手机号" style="width:210px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="action">
        <el-button v-if="$check('health-user-mgt-root_add')" type="primary" icon="el-icon-plus" @click="handleAdd">新 增</el-button>
        <el-button @click="exportList" :loading="exportLoading">{{ exportText }}</el-button>
      </div>

      <!-- list -->
      <el-table :data="userList">
        <el-table-column align="center" width="55" type="index" label="序号" fixed></el-table-column>
        <el-table-column align="center" width="150" prop="name" label="姓名" fixed></el-table-column>
        <el-table-column align="center" prop="orgName" label="机构名称" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" width="200" prop="roleName" label="角色名称">
          <template slot-scope="{ row }">{{ row.adminType === 0 ? '系统管理员' : row.roleName }}</template>
        </el-table-column>
        <el-table-column align="center" width="120" prop="phone" label="手机号"></el-table-column>
        <el-table-column align="center" width="80" prop="status" label="状态">
          <template slot-scope="{ row }">
            <el-switch
              :disabled="row.adminType === 0"
              v-model="row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2"
              @change="handleChangeStatus($event, row.id)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="操作" fixed="right">
          <template slot-scope="{ row }">
            <div>
              <span v-if="$check('health-user-mgt-root_edit')" class="action-btn mr-24" @click="handleEdit(row)">修改</span>
              <span v-if="$check('health-user-mgt-root_reset')" class="action-btn" @click="handleResetPwd(row)">重置密码</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- 新增/编辑 弹窗 -->
    <UserDialog ref="userDialogRef" @on-success="onSuccess" />
  </div>
</template>

<script>
import { getItem } from '@/utils/storage.js';
import downloadFileFunc from '@/utils/download-file.js';

import OrgTree from '@/components/OrgTree';
import UserDialog from './userDialog.vue';

import { resetPwd } from '@/api/general-user/person-center.js';
import { getUserList, toggleStatus, exportUserList } from '@/api/general-user/user-management.js';

export default {
  components: { OrgTree, UserDialog },
  data() {
    return {
      exportLoading: false,
      exportText: '导出人员清单',
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        orgId: '',
        search: ''
      },
      userList: []
    };
  },
  created() {
    this.getUserListData();
  },
  methods: {
    getUserListData() {
      getUserList(this.queryForm).then(res => {
        this.total = res.data.total;
        this.userList = res.data.records;
      });
    },
    // 节点被点击时回调
    handleNodeClick(data) {
      this.queryForm.orgId = data.id;
      this.handleSearch();
    },
    // 分页
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getUserListData();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getUserListData();
    },
    // 查询
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getUserListData();
    },
    // 重置
    handleReset() {
      this.$refs.form.resetFields();
      this.$refs.treeRef.reset();
      this.queryForm.orgId = '';
      this.getUserListData();
    },
    handleChangeStatus(status, id) {
      const appId = getItem('appInfo').appId;
      const params = { id, appId, status };
      toggleStatus(params).then(res => {
        this.$message.success('修改用户状态成功！');
      });
    },
    handleResetPwd(row) {
      this.$confirm('是否确认重置此用户密码?重置后密码： abc123456', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return resetPwd({ id: row.id });
        })
        .then(res => {
          this.$message.success('重置密码成功！');
        });
    },
    handleAdd() {
      this.$refs.userDialogRef.open();
    },
    onSuccess(id) {
      id ? this.getUserListData() : this.handleReset();
    },
    handleEdit(row) {
      this.$refs.userDialogRef.open(row);
    },
    exportList() {
      this.exportLoading = true;
      this.exportText = '导出中...';
      exportUserList({ orgId: this.queryForm.orgId })
        .then(res => {
          downloadFileFunc(res);
        })
        .finally(() => {
          this.exportLoading = false;
          this.exportText = '导出人员清单';
        });
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  height: 100%;
  display: flex;
  .action {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }
  .table {
    width: calc(100% - 260px);
  }
}
</style>
